<template>
    <div>
      <b-container class="mt-5 mb-5">
        <h1 class="fs-4 font-weight-bold mb-4">Bedrijfsgegevens</h1>
        <p class="text-muted">Om te kunnen starten met de verkoop hebben we nog een aantal gegevens van je bedrijf nodig. Nadat je deze hebt aangeleverd kun je direct starten met de verkoop.</p>

        <b-card class="mb-4">
          <div class="mb-3">
            <label class="required">Rechtsvorm</label>
            <b-form-input/>
          </div>
          <div class="mb-3">
            <label class="required">E-mailadres</label>
            <b-form-input/>
          </div>
          <div class="mb-3">
            <label class="required">Telefoonnummer</label>
            <b-form-input/>
          </div>
        </b-card>

        <b-card class="mb-4">
          <h3>Bestuurders</h3>
          <p>Vul de gegevens in van alle bestuurders van dit bedrijf.</p>
          <b-row>
            <b-col cols="4">
              <div class="mb-3">
                <label class="required">Aanhef</label>
                <b-form-input/> Dhr/Mevr.
              </div>
            </b-col>
            <b-col cols="4">
              <div class="mb-3">
                <label class="required">Voornaam</label>
                <b-form-input/>
              </div>
            </b-col>
            <b-col cols="4">
              <div class="mb-3">
                <label class="required">Achternaam</label>
                <b-form-input/>
              </div>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="4">
              <div class="mb-3">
                <label class="required">E-mailadres</label>
                <b-form-input/> Dhr/Mevr.
              </div>
            </b-col>
            <b-col cols="4">
              <div class="mb-3">
                <label class="required">Geboortedatum</label>
                <b-form-input/>
              </div>
            </b-col>
            <b-col cols="4">
              <div class="mb-3">
                <label class="required">Geboorteplaats</label>
                <b-form-input/>
              </div>
            </b-col>
          </b-row>
        </b-card>
        
      </b-container>
    </div>
  </template>
  
  <script>
      export default {
          metaInfo: {
              title: 'Organisatie',
          },
          components: {
          },
          data() {
              return {
                events: []
              }
          },
          computed: {},
          methods: {},
          created() {}
      }
  </script>